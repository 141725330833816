<template>
    <div class="border-round-2xl bg-bluegray-50 p-4 w-full flex flex-column gap-3">
        <template v-if="!loading">
            <SecaoExpansivelFuturos v-for="funcionario of funcionarios" :key="funcionario.id_agendamento" :funcionario="funcionario">
                <TabelaExames v-if="!isMobile" :funcionario="funcionario" modo-exibicao />
                <template v-else>
                    <FuncionarioMobileExame
                        v-for="procedimento of funcionario.procedimentos"
                        :key="procedimento.id"
                        modo-exibicao
                        :procedimento="procedimento"
                        :dadosAgendamento="obterDadosAgendamento(funcionario)"
                    />
                </template>
            </SecaoExpansivelFuturos>
        </template>
        <div v-else class="flex flex-column gap-4">
            <Skeleton width="100%" height="5rem" />
            <Skeleton width="100%" height="5rem" />
            <Skeleton width="100%" height="5rem" />
            <Skeleton width="100%" height="5rem" />
        </div>
    </div>
</template>

<script>
import SecaoExpansivelFuturos from './components/SecaoExpansivelFuturos.vue';
import TabelaExames from '../TabelaExames/Index.vue';
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';
import { logoutPrestador } from '@/services/auth';
import FuncionarioMobileExame from '../PainelAgendamentosPendentes/components/CardFuncionario/components/FuncionarioMobileExame.vue';
import moment from 'moment-timezone';
import { useWindowSize } from '@vueuse/core';
export default {
    components: { TabelaExames, FuncionarioMobileExame, SecaoExpansivelFuturos },
    setup() {
        const { width } = useWindowSize();
        return { width };
    },
    data() {
        return {
            funcionarios: [],
            loading: false
        };
    },
    computed: {
        isMobile() {
            return this.width < 576;
        }
    },
    methods: {
        async carregarFuncionarios() {
            this.loading = true;
            try {
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                const { data } = await getClientBase(authToken).get(`/acesso-prestador/funcionarios-exames/agendamentos/confirmados`);
                this.funcionarios = data;
            } catch (error) {
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/confirmacao-prestador/');
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao processar sua solicitação',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
            this.loading = false;
        },
        obterDadosAgendamento(funcionario) {
            const { id_agendamento, periodo_agendamento, data_sugestao_inicial, data_sugestao_final } = funcionario;
            return {
                id_agendamento,
                periodo_agendamento,
                data_sugestao_inicial: moment(data_sugestao_inicial).toDate(),
                data_sugestao_final: moment(data_sugestao_final).toDate()
            };
        }
    },
    async mounted() {
        await this.carregarFuncionarios();
    }
};
</script>

<style lang="scss" scoped></style>
