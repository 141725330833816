<template>
    <div>
        <div class="border-1 border-bluegray-200 border-round-xl bg-white">
            <Panel ref="menu" toggleable collapsed>
                <template #header>
                    <div class="grid px-3 text-bluegray-800 bold-weight my-0" style="width: 550px; max-width: 100%">
                        <div class="pr-3 flex align-items-center justify-content-between w-full md:w-fit">
                            <div class="flex align-items-center gap-2 md:col-7">
                                <i class="pi pi-id-card" />
                                <span class="md:w-18rem"> {{ funcionario.nome_funcionario }} </span>
                            </div>
                            <div>
                                <Button
                                    icon="pi pi-ellipsis-v"
                                    aria-label="Mais opções"
                                    style="width: 20px; height: 20px"
                                    class="md:hidden p-3 p-button-text text-pink-500"
                                    @click="toggleMenu"
                                    :loading="loadingKit"
                                />
                                <TieredMenu ref="tieredMenu" id="overlay_tmenu" :model="menuItems" popup />
                            </div>
                        </div>
                        <Button
                            badge-class="bg-red-100"
                            label="Mais detalhes"
                            icon="pi pi-plus-circle"
                            icon-class="text-pink-500"
                            class="hidden md:block p-button-text text-bluegray-800 bold-weight"
                            iconPos="left"
                            @click="acessarDetalhes"
                            :loading="loadingDetalhes"
                        />
                    </div>
                </template>
                <template #icons>
                    <div class="flex align-items-center text-bluegray-800 medium-weight font-bold gap-4">
                        <Button
                            badge-class="bg-red-100"
                            label="Baixar kit de atendimento"
                            icon="pi pi-download"
                            icon-class="text-pink-500"
                            class="hidden md:block p-button-text text-bluegray-800 bold-weight"
                            iconPos="left"
                            @click="baixarKitAtendimento"
                            :loading="loadingKit"
                        />
                        <Button
                            class="bg-bluegray-50 border-round border-1 border-bluegray-100 text-pink-500 p-4"
                            style="width: 24px; height: 24px"
                            :icon="`pi pi-angle-${$refs.menu ? ($refs.menu['d_collapsed'] ? 'down' : 'up') : 'down'}`"
                            @click="toggle"
                        />
                    </div>
                </template>
                <template #default>
                    <slot />
                </template>
            </Panel>
        </div>
        <DialogDetalhes agendamento-futuro :funcionario="funcionario" ref="dialogDetalhes" />
    </div>
</template>

<script>
import DialogDetalhes from '../../DialogDetalhes/Index.vue';
import { logoutPrestador } from '@/services/auth';
import { getClientBase } from '@/services/http';
import { getPrestadorToken } from '@/common/storage';

export default {
    components: { DialogDetalhes },
    props: {
        funcionario: { type: Object, required: true }
    },
    data() {
        return {
            loadingDetalhes: false,
            loadingKit: false,
            menuItems: [
                {
                    label: 'Mais Detalhes',
                    icon: 'pi pi-plus-circle',
                    command: this.acessarDetalhes
                },
                {
                    label: 'Baixar kit de atendimento',
                    icon: 'pi pi-download',
                    command: this.baixarKitAtendimento
                }
            ]
        };
    },
    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        toggleMenu(event) {
            this.$refs.tieredMenu.toggle(event);
        },
        async acessarDetalhes() {
            this.loadingDetalhes = true;
            await this.$refs.dialogDetalhes.acessarDetalhes();
            this.loadingDetalhes = false;
        },
        async baixarKitAtendimento() {
            try {
                this.loadingKit = true;
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                const response = await getClientBase(authToken).get(`acesso-prestador/download/kit/agendamento/${this.funcionario.id_agendamento}`, {
                    responseType: 'blob'
                });

                const blobUrl = window.URL.createObjectURL(response.data);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', 'kit_atendimento.zip');

                link.click();

                window.URL.revokeObjectURL(blobUrl);
            } catch (error) {
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/confirmacao-prestador/');
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao processar sua solicitação',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
            this.loadingKit = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

.regular-weight {
    font-family: Poppins-Regular, sans-serif;
}

.medium-weight {
    font-family: Poppins-Medium, sans-serif;
}

.bold-weight {
    font-family: Poppins-Bold, sans-serif;
}

:deep(.p-panel-header),
:deep(.p-panel-content) {
    font-family: Poppins-Bold, sans-serif;
    border: none;
}

:deep(.p-panel-header) {
    background-color: white;
    border-radius: 8px;
}

:deep(.p-panel-content) {
    position: relative;
    padding: 12px 36px;
    border-radius: 8px;
}

:deep(.p-panel-toggler) {
    display: none;
}
</style>
